/* css start from here for desktop mode * make sure this is not responsive code ,*/ 


/* root for color and font size */






:root
{
    --color1:rgb(9, 9, 9);
    --color2:skyblue;
    --color3:whitesmoke;
    --color4:rgb(59, 54, 18);
    --padding:2rem 0 2rem 0;
    --head1:3rem;
    }


    *
    {
        padding:0rem;
        margin:0rem;
    }


/* header css start from here */


.header
{
background-color:var(--color1);
position:sticky;
top:0;
padding:var(--padding);
z-index:2;
box-shadow: rgba(242, 243, 244, 0.1) 0px 10px 10px -10px;


}

.navbar_icon
{
    display:none;
}

.header_child
{
    padding:0rem !important;
    display:flex;
    justify-content:center;
    align-items:center;
    margin: auto !important;

}



.header_left>h5
{
    
    background-image: linear-gradient(to right, #f36249, #0814f1,#f36249);
    -webkit-background-clip: text; /* This is key for applying gradient to text */
    color: transparent;
    font-size:2rem;
    margin:0rem !important;
    word-spacing:2rem;
}

.header_left,.header_right
{
margin:0rem !important;
padding:0rem !important;
}


.nav_list
{
display: flex;
justify-content: space-between;
align-items: center;
color:var(--color3);
text-transform:capitalize;
width:100% !important;
}

.active1
{
text-decoration: none;
color:var(--color3);
font-weight: bolder;

}

.nav_list>li
{
    list-style: none !important;
    font-size: var(--parh);

}



/* hero section */


.hero_section
{
    padding:var(--padding);
    background-color: var(--color1);
    
}

.hero_section_child
{
    margin:auto !important;
    padding:0rem !important;
    height:30rem;
    padding:0rem !important;
}

.hero_section_child_left,.hero_section_child_right
{
    color:white;
    height:auto;
    width:auto;
    display:flex;
    justify-content:center;
    align-items:flex-start;
    flex-direction:column;
    text-transform: capitalize;
    padding:0rem !important;
}

.hero_section_child_left>p
{
    padding-top:2rem;
    font-weight: normal !important;
    font-size:var(--parh);
    line-height:2rem;
   

}

.hero_section_child_left>h1 
{
    font-size:var(--head1);
    background-image: linear-gradient(white,rgb(237, 236, 248));
    -webkit-background-clip: text; /* This is key for applying gradient to text */
    color: transparent;

    
         /* Hide the actual text */
}

.hero_section_child_left>h1::first-line 
{
    font-size:4rem;
    background-image: linear-gradient(to right,left, #f36249, #0814f1);
    -webkit-background-clip: text; /* This is key for applying gradient to text */
    color: transparent;

}


.hero_section_child_right>img
{
width:100%;
height:80%;
}

/* who we are */

.about_section
{
background-color: var(--color1);   
    color:var(--color3);
    padding:var(--padding);
    height:30rem;
    display:flex;
    justify-content:center;
    align-items: center;
    
}

.about_section_child
{
    padding:0rem !important;
    margin:auto !important;
    height:auto !important;
    display:flex;
    justify-content:center;
    align-items: center;
    

    

}

.about_section_child_left, .about_section_child_right
{
    display:flex;
    flex-direction:column;
    align-items:flex-start;
    justify-content: center;
    height:100% !important;
    padding:0rem !important;
    

}

.about_section_child_left>h2 
{
   
    background-image: linear-gradient(to right, #f36249, #0814f1);
    -webkit-background-clip: text; /* This is key for applying gradient to text */
    color: transparent;
    font-size:3rem;
}

.about_section_child_right
{
    display:flex;
    justify-content:center;
    align-items:center;
}





.about_section_child_left>p
{
padding-top:2rem;
line-height:2rem;



}

.button_talkus
{
    width:10rem;
    height:10rem;
    border:3px dotted white;
    border-radius:50%;
    display:grid;
    place-items:center;

}

/* our network */

.arrow_white
{
    color:var(--color3);
}

.service_provide
{
padding:var(--padding);
background-color: var(--color1);


}

.title_service
{
    margin: auto !important;
    color:var(--color3);
    text-align: center;
    padding:0rem !important;
}

.title_service_child
{
    padding:0rem !important;
}

.title_service_child>h2
{
font-size:3rem;
background-image: linear-gradient(to right, #f36249, #0814f1);
-webkit-background-clip: text; /* This is key for applying gradient to text */
color: transparent;
}

.title_service_child>p
{
padding-top:2rem;
font-size:1.5rem;

}

.service_provide_child
{
    margin:auto !important;
    color:white;
    padding:0rem !important;
    margin-top:8rem !important;
    
}

.sevice_card_left,.sevice_card_right 
{
    padding:0rem !important;
}

.service_card_right
{
    display:flex;
    justify-content: center;
    align-items:center;
    
}

.service_card_right_content>img 
{
   width:20rem;
   height:15rem;

}

.sevice_card_left>h4
{
color:skyblue;
font-size:3rem;
}

.sevice_card_left>p
{
color:var(--color3);
padding-top:2rem;
line-height:2rem;
}


/* counter section */

.couter_section
{
    padding:var(--padding);
    background-color:var(--color1);
    color:var(--color3);

}

.counter_section_child
{
    margin:auto !important;
    padding:0rem !important;
    padding:var(--padding) !important;
    background-image: linear-gradient(to right, #f36249, #0814f1,white);
-webkit-background-clip: text; /* This is key for applying gradient to text */
color: transparent;
    

}

.counter_section_child_card
{
    display:flex;
    justify-content:center;
    align-items:center;
    flex-direction:column;
    text-align:center;

    
}

.counter_section_child_card>h2 
{
    font-size:3rem;
}

.counter_section_child_card>h4 
{
    font-size:2.5rem;
    padding-top:1rem;
}

/* pricing */



.pricing_section {
    padding:4rem 0 4rem 0;
    background-color: var(--color1);
}

.pricing_section_child
{
    margin: auto !important;
    padding:0rem !important;

}

.pricing_section_child_scroll {
    height:100%;
    padding:0rem;
    margin-top:2rem !important;
    padding:0rem !important;

    white-space: nowrap;
    overflow:auto;



}

.card_pricing_content
{
    padding:1rem;
    overflow:auto;
    color:var(--color3);
    max-width: 100%;
    display:flex;
    justify-content:space-between;
    align-items:center;
    

}

.card_pricing_content>p 
{
    word-wrap: break-word;
}





.pricing_section_child_scroll::-webkit-scrollbar {
    display: none; /* Hides scrollbar in WebKit browsers */
}

.card_pricing {
    height:100%;
    padding:0rem !important;
    display:block !important;
    width:23rem !important;
    display:inline-block !important;
    border:1px solid rgb(43, 42, 42);
    box-sizing: border-box;
    border-radius:5px;
    

}


.card_pricing:nth-child(n+2) {
    margin-left:1rem;
}


.card_pricing div>ul>li 
{
    color:var(--color3);
    padding-top:1rem;

}

.card_pricing_content>h2 
{
    background-image: linear-gradient(to right, #f36249, #0814f1);
    -webkit-background-clip: text; /* This is key for applying gradient to text */
    color: transparent;
    font-size:3rem;
}

.card_pricing div>ul>li::marker {
    color: rgb(30, 30, 252); /* Change the color of the bullet points */
  }



.title_pricing
{
margin:auto !important;
padding:0rem !important;

}

.title_pricing>h2 
{
    background-image: linear-gradient(to right, #f36249, #0814f1);
-webkit-background-clip: text; /* This is key for applying gradient to text */
color: transparent;
font-size:3rem;
padding:0rem !important;
margin:0rem !important;

}
 
 
  




/* get in touch */

.form_container
{
    background-color:var(--color1);
    padding:var(--padding);
    background-size: cover;
    height:auto;
    padding-top:4rem;
    border-top:1px solid rgb(68, 66, 66);
    padding-bottom:3rem !important;
  
}



.form_container_child>h2 
{
font-size:3rem !important;
color:var(--color3) !important;
}

.form_container_child
{
    margin: auto !important;
    padding:0rem !important;
    color:white;
    padding-bottom:3rem !important;


}

.form_container_child_left input 
{
    display:block;
    width:30rem;
    height:3rem;
    margin-top:1rem !important;
    background:transparent;
    border-bottom:1px solid white !important;
    outline:none !important;
    color:var(--color3);
    padding-left:1rem;
    border:none;

    
}

.input_box
{
    display:flex;
    justify-content:center;
    align-items:center;
}

.form_icon
{
    font-size:2rem;
    color: #c3c1c5;

}

.form_container_child_right
{
    display:flex;
    justify-content:center;
    align-items:center;
    padding:0rem !important;

}



.form_container_child>h2 
{
    background-image: linear-gradient(to right, #f36249, #0814f1);
-webkit-background-clip: text; /* This is key for applying gradient to text */
color: transparent;
font-size:4rem;
}

.form_container_child_left label 
{
font-size:1.5rem;
padding-top:1rem;
}

.form_container_child_left button 
{
    width:10rem ;
    height:3rem;
    border:none;
    outline:none;
    color:var(--color3);
    margin-top:3rem;
    border:1px solid whitesmoke;
    background:transparent;
    border-radius:5px;
}

.form_container_child_right_box
{
    width:20rem;
    height:20rem;
    background-color:var(--color1);
    border:1rem dotted #5633ee;
    border-radius:50%;

}

.form_container_child_right_box .pen 
{
    font-size:8rem;
    position:relative;
    top:4rem;
    left:5rem;
    color:#5633ee;

}


/* footer section */

.footer_section
{
    padding:var(--padding);
    background-color:var(--color1);
    border-top:1px solid grey;
}

.footer_section_child
{
    margin:auto !important;
    color:var(--color3);
    padding:0rem !important;
    height:auto;
    display:flex;
    justify-content:space-between;
    align-items:flex-start;
    flex-direction:row;
    padding:var(--padding) !important;
}

.footer_section_child_subchild>h5
{
       
    background-image: linear-gradient(to right, #f36249, #0814f1,#f36249);
    -webkit-background-clip: text; /* This is key for applying gradient to text */
    color: transparent;
    font-size:2rem;
}

.footer_section_child_subchild
{
    padding:0rem !important;
    width:22% !important;
    height:auto !important;
    display:flex;
    justify-content:center;
    flex-direction:column;
    box-sizing: border-box;

    
}

.footer_section_child_subchild1
{
    width:40% !important;
}

.footer_section_child_subchild1>p 
{
    padding-top:1rem;
}

.footer_section_child_subchild>ul>li 
{
    padding-top:2rem !important;
list-style:none;

}

.footer_section_child_subchild>p 
{
    line-height:2rem;
}



.footer_section_child_subchild>ul 
{
    padding:0rem !important;
}

.social_box>ul>li 
{
    font-size:1.8rem;
}

.subfooter_section
{
    margin:auto !important;
    border-top:1px solid rgb(55, 54, 54);
    text-align:center;
    padding-top:1rem;
    color:white;
    
}



/* read css */

.read_container
{
    background-color:var(--color3);
    padding:var(--padding);
    height:100vh !important;
}

.read_container_child
{
    margin: auto !important;
    padding:0rem !important;
    border:1px solid rgb(235, 232, 232);
    height:30rem ;
    overflow: auto !important;
    margin-top:1rem !important;

}

.read_container_title
{
   margin: auto !important;
   padding:0rem !important;
}

.read_container_title>h2 
{
   color:rgb(81, 81, 251);
   padding:0rem !important;
}

.read_container_child tr 
{
    padding:0rem !important;
}

.read_container_child tr:first-child
{
position:sticky;
top:0rem;
width:100% !important;

}


.read_container_child tr>th 
{
    border:1px solid rgb(184, 180, 180);
    width:15rem !important;
    padding:1rem;
    box-sizing: border-box !important;

}


.read_container_child .data_ret>td
{
    border:1px solid rgb(195, 191, 191);
    width:15rem !important;
    box-sizing: border-box !important;
    padding:0.5rem;
    height:2rem !important;

}

.button_delete
{
    width:5rem;
    height:2rem;
    background-color:rgb(115, 115, 247);
    border:none;
    outline:none;
}








