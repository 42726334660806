@media only screen and (min-width:0rem) and (max-width:80rem)

{


    /* header css responsive */

.header_right
{
    position:absolute;
    top:5rem;
    left:0rem;
    background-color:black;
    height:30rem !important;
    transition: all 0.5s ease-in;


}

.header_right {
    display: none;
  }
  
  .header_right.visible {
    display: block;
  }
  

.header_right .nav_list
{
    width:100% !important;
    height:100% !important;
    display:flex;
    justify-content:center;
    align-items:flex-start;
    flex-direction:column;
    margin:0rem !important;
    padding:1.8rem;
}

.header_right .nav_list li:nth-child(n+1)
{
    padding-top:1.8rem !important;
    font-size:2rem;
}






.nav_list>li 
{
    display:block !important;
}

.navbar_icon
{
    display:block !important;
    color:wheat !important;
    font-size:2rem !important;
}

.header_left
{
    display:flex;
    justify-content:space-between;
    align-items:center;
}



/* hero section css */

.hero_section
{
    height:auto !important;
}


.hero_section_child
{
    height:auto !important;

}


/* who am i css responsive */

.about_section
{
    height:auto !important;
}

/* footer section */

.footer_section_child
{
    display:flex;
    flex-direction:column;
    
}

.footer_section_child_subchild
{
    width:100% !important;
}

.social_box>ul
{
    display:flex;
    justify-content:space-around;
    align-items:center;
    flex-direction:row;
}

.footer_section_child_subchild:nth-child(n+1)
{
    margin-top:2rem;
}

/* get in touch */


.input_box
{
    width:90% !important;
}

.form_container_child_right
{
    padding-top:4rem !important;
}


/* card pricing css responsive */

.card_pricing
{
    width:21rem !important;
}

/* counter record section */

.counter_section_child_card:nth-child(n+2)
{
    margin-top:2rem !important;
}


/* service section css */


.service_card_right_content
{
    width:100% !important;
    margin:0rem !important;
    padding:0rem !important;
    padding-top:4rem !important;

}

.service_card_right_content>img 
{
width:100% !important;
padding:0rem !important;
}

.title_service_child>p
{
    font-size:1rem;
    line-height:2rem;
    padding-top:2rem;
    text-align:left !important;
    word-break: break-all;

}

.title_service_child
{
    text-align:start !important;
}


/* read page css */

.read_container_child
{
    height:25rem !important;
    width:100% !important;
    
}

.read_container_child tr:first-child
{
    width:100% !important;
    border:1px solid red;

}





}